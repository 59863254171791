import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from '../components/seo';
import Top from '../components/blocks/top';

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="404: Not Found" />
      <Top item={{ text: '', small: true }} />

      <section className={`content`}>
        <div className="container-fluid">
          <div className={`row justify-content-md-start`}>
            <div className="col-12 col-md-7 title">
              <h1>404</h1>
            </div>
          </div>
          <div
            className={`row align-items-center justify-content-center justify-content-md-start`}
          >
            <div className="col-12 col-md-7 text">
              <p>Sidan hittades inte.</p>
              <p>
                <Link to="/">Gå tillbaka.</Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
